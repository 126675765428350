<template lang="pug">
  .list-item(v-if='item')
    .item-l(v-if="item.infoImage")
      el-image(:src="item.infoImage" width="240px" height="140px")
    .item-c(@click="onChangeItem(2)")
      .title {{item.title}}
      .desc(v-html="item.introduction")
      .date {{item.createTime}} 
    .item-r(v-if="readonly")
      div(@click="onChangeItem(0)")
        span.edit(class="iconfont icon-bianji")
        span 编辑
      div(@click="onChangeItem(1)")
        span.delete(class="iconfont icon-shanchu") 
        span 删除
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    fileUrl: {
      type: Array,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  methods: {
    onChangeItem(idx) {
      // idx:0 编辑 1:删除 2:标题
      this.$emit("onChangeItem", idx, this.item,this.fileUrl);
    },
  },
};
</script>

<style lang="less" scoped>
.list-item {
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  height: 160px;
  border: 1px solid #1c2a75;
  border-radius: 2px;
  .df;
  & > div {
    height: 100%;
  }
  .delete,
  .edit {
    height: 16px;
    width: 16px;
    background-size: 100% 100%;
  }
  .edit {
    // background: url("../assets/icon/edit-icon.svg");
    // background-color: #38bbff;
  }
  .delete {
    // background: url("../assets/icon/delete-icon.svg");
    // background-color: #eb604b;
  }
  &:hover {
    background: rgba(42, 59, 158, 0.3);
  }
  /deep/ .el-image {
    height: 100%;
    width: 100%;
    border-radius: 2px;
  }
  .item-l {
    width: 224px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .item-c {
    padding: 15px;
    flex: 1;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces !important;
    cursor: pointer;
    .df;
    .dfc;
    .dfjs;
    & > div {
      width: 100%;
    }
    /deep/ .gm-scroll-view {
      flex: 1;
    }
    .title {
      font-size: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-bottom: 10px;
      min-height: 20px;
    }
    .desc {
      margin-top: 10px;
      overflow: hidden;
      p {
        white-space: break-spaces !important;
      }
    }
    .date {
      margin-top: 10px;
    }
    .date,
    .desc {
      color: #5a86d3;
      font-size: 14px;
      line-height: 1.4rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .item-r {
    width: 160px;
    padding: 15px 0;
    .df;
    .dfjs;
    align-items: flex-start;
    & > div {
      .df;
      .dfac;
      cursor: pointer;
      span {
        padding: 0 10px;
      }
    }
    & > div:first-child {
      color: #38bbff;
    }
    & > div:last-child {
      color: #eb604b;
    }
  }
}

/deep/ .desc {
  img {
    display: none;
  }
}
</style>
