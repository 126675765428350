<template lang="pug">
  .tinymce-w(:class='className')
    tinymce(id="tinymce" ref="tinymce" v-model="content" :other_options="other_options")
</template>

<script>
import "tinymce/skins/ui/oxide/skin.css";
export default {
  props: {
    className: {
      type: String,
      default: "",
    },
    contents: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      content: "",
      tinymceId: "tinymce",
      other_options: {
        // language_url: "/public/tinymce/langs/zh_CN.js", //语言包的路径
        language: "zh_CN", //语言
        elementpath: false, // 隐藏底栏的元素路径
        branding: false, // 隐藏右下角技术支持
        contextmenu: false, // 禁用富文本的右键菜单，使用浏览器自带的右键菜单
        height: "13rem",
        skin_url: "/public/tinymce/skins/ui/oxide", //skin路径
        content_css: "/public/tinymce/skins/content/default/content.css",
        content_style: "p {color: #ffffff;}",
        menubar: "file edit insert view format table",
        resize: false, //禁用编辑器调整大小
        fontsize_formats: "11px 12px 14px 16px 18px 24px 36px 48px",
        toolbar1:
          "code preview | undo redo | forecolor textcolor backcolor textcolor | formatselect fontsizeselect | formatselect | bold italic |",
        toolbar2:
          "alignleft aligncenter alignright alignjustify outdent indent | numlist lists | image table | fullscreen fullscreen|",
        //开关使用Data URL/Blob URL插入图片和文件到内容区的方式。例如，图像是使用imagetools插件处理后插入到内容区的，此时图像并未真正上传到服务器，而是以Data URL/Blob URL的方式插入在内容中。
        automatic_uploads: false,
        //图片上传地址
        images_upload_url:
          process.env.NODE_ENV === "production"
            ? window.g.UPLOADER_URL
            : "/api/common/uploadFile",
        // file_picker_types: "image",
        //文件上传成功时的回调
        // file_picker_callback: () => {},
        //自定义图片上传
        images_upload_handler: function(blobInfo, success, failure, progress) {
          var xhr, formData;
          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          xhr.open("POST", process.env.NODE_ENV === "production"
              ? window.g.UPLOADER_URL
              : "/api/common/uploadFile");
          xhr.upload.onprogress = function(e) {
            progress((e.loaded / e.total) * 100);
          };

          xhr.onload = function() {
            var json;
            if (xhr.status == 403) {
              failure("HTTP Error: " + xhr.status, { remove: true });
              return;
            }
            if (xhr.status < 200 || xhr.status >= 300) {
              failure("HTTP Error: " + xhr.status);
              return;
            }
            json = JSON.parse(xhr.responseText);
            console.log(json);
            if (!json || typeof json.resultObject != "string") {
              failure("Invalid JSON: " + xhr.responseText);
              return;
            }
            success(json.resultObject);
          };

          xhr.onerror = function() {
            failure(
              "Image upload failed due to a XHR Transport error. Code: " +
                xhr.status
            );
          };

          formData = new FormData();
          formData.append("files", blobInfo.blob(), blobInfo.filename());

          xhr.send(formData);
        },
      },
    };
  },
  methods: {
    setContent(value = "") {
      console.log(value);
      this.content = value;
      window.tinymce.get("tinymce").setContent(value);
    },
  },
};
</script>

<style></style>
