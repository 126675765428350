<template lang="pug">
    .box2-w(:style='{height:h,width:w}' :class='className')
      <slot></slot>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: "",
    },
    h: {
      type: [Number, String],
      default: "auto",
    },
    w: {
      type: [Number, String],
      default: "100%",
    },
  },
  data() {
    return {};
  },
  computed: {
    boxStyle() {
      return {
        height: this.h.is,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.box2-w {
  position: relative;
  min-height: 60px;
  width: 100%;
  background: rgba(10, 21, 78, 0.8);
  box-shadow: inset 0px 0px 8px 3px #022f78;
  padding: 10px;
  color: #fff;
}
</style>
