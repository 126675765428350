<template lang="pug">
  div.my-modal-box(:class='className')
    elDialog(:visible.sync="dialogVisible" :margin-top="marginTop"  width="50%" :modal="modal" @close="onClose")
      Box2(className="my-box2")
        span.m-title {{title}}
        .my-modal-body
          slot()
</template>

<script>
import Box2 from "./Box2.vue";
export default {
  name: "MyModal",
  components: { Box2 },
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
    marginTop: {
      type: String,
      default: "15vh",
    },
    title: String,
    className: {
      type: String,
      default: "",
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  watch: {
    modalShow(newVal) {
      this.dialogVisible = newVal;
    },
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped lang="less">
.m-title {
  color: #70aaff;
  font-size: 20px;
  text-align: center;
}
.my-box2 {
  .df;
  .dfc;
  padding: 20px;
}
.my-modal-body {
  height: 100%;
  padding: 60px 10px 10px 10px;
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__headerbtn {
  position: absolute;
  top: 5%;
  right: 5%;
  color: #fff;
  padding: 6px;
  z-index: 6;
  i {
    color: #2179d6;
    font-size: 36px;
  }
}
/deep/ .el-dialog {
  background: transparent;
}
</style>
